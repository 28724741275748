import React from "react"
import Layout from "../components/layout"
// import ScrollAnimation from "react-animate-on-scroll"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import WebriQForm from "@webriq/gatsby-webriq-form"

import { FcBullish, FcOrgUnit, FcSettings } from "react-icons/fc"

import "./our-approach.css"
import Banner from "../components/hero/banner-approach.js"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const QuandaryValues = loadable(() =>
  pMinDelay(import("../components/new-quandary/quandary-value-approach"), 500)
)
const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper"), 500)
)
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)
const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))

const TestimonialSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper-contact.js"), 500)
)

class OurApproachPage extends React.Component {
  render() {
    // const { data } = this.props
    // const caseStudy = data.allSanityCaseStudy.edges
    return (
      <Layout location={this.props.location} banner={<Banner />}>
        <Seo
          title="Our Approach | Quandary Consulting Group"
          description="Learn how our agile process helps us build custom applications and integrations for mid-market and enterprise organizations (in weeks) using low-code. "
        />
        <div className="page-content pb-0 pt-0">
          <PartnerSwiper />
          <LazyLoadComponent>
            <section className="pt-sm-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center text-left-mobile">
                    <h2>Our Process</h2>
                    <p>
                      We work as an extension of your team, building custom
                      solutions that fit the exact needs of your business
                      without extensive delays and costs.
                    </p>
                  </div>
                </div>
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-sm-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile align-self-baseline">
                    <h3>Step 1: Scope and Prioritization</h3>
                    <p className="mt-4">
                      Our discovery process identifies your challenges and
                      goals. We ask a lot of questions to find out what’s
                      working and what’s not, so we can fix what’s broken and
                      replicate what works.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex"
                      src="../images/new-images/approach/phase1.jpg"
                      alt="Phase 1"
                      layout="constrained"
                      style={{ height: "25rem" }}
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="row align-items-center flex-lg-row flex-md-row mt-3">
                  <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex"
                      src="../images/new-images/approach/phase2.jpg"
                      alt="Phase 2"
                      layout="constrained"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile align-self-baseline">
                    <h3>Step 2: Diagram Sprint Requirements</h3>
                    <p className="mt-4">
                      Through regular interaction with your team, we identify
                      and outline the requirements for each solution. This
                      ensures we create applications that meet your needs.
                    </p>
                    <p className="mt-4">
                      We also work with your team to redefine how work gets done
                      in your organization. Whether you need entirely new
                      workflows or you want to improve your existing ones.
                    </p>
                  </div>
                </div>
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile align-self-baseline">
                    <h3>Step 3: Development and Iteration</h3>
                    <p className="mt-4">
                      We use an agile approach to software development, starting
                      with your highest priority items. And we build the
                      applications your team needs, using short (usually
                      2-weeks) sprints.
                    </p>
                    <p className="mt-4">
                      This iterative process gets our teams working together
                      regularly to collect and implement feedback, increasing
                      success and adoption rates. Once ready, we release the new
                      application and help your team with adoption. At the same
                      time, we phase out the old systems and workflows.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex"
                      src="../images/new-images/approach/phase3.jpg"
                      alt="Phase 3"
                      layout="constrained"
                      style={{ height: "30rem" }}
                      loading="lazy"
                    />
                  </div>
                </div>
                <div>
                  <div className="row align-items-center my-3">
                    <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex"
                        src="../images/new-images/approach/phase4.jpg"
                        alt="Phase 4"
                        layout="constrained"
                        style={{ height: "30rem" }}
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile align-self-baseline">
                      <h3>Step 4: Continuous Improvement</h3>
                      <p className="mt-4">
                        After designing and deploying the initial application,
                        we work with your team to identify new operational weak
                        points. Through our approach to continuous improvement,
                        we keep your business agile, efficient, and competitive
                        in your market.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
          <LazyLoadComponent>
            <section
              id="peaks-methodology"
              className="peaks"
              style={{
                padding: "3rem 0",
                background: "#ecf0f2",
                scrollMarginTop: "3.5rem",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h2>
                      Our <span className="font-italic mr-1">"</span>PEAKS
                      <span className="font-italic">"</span> Methodology
                    </h2>
                    {/* <p>
                      PEAKS serves as our foundation for every project we
                      complete. This ensures you receive the highest level of
                      care, commitment, and expertise.
                    </p> */}
                    <p>
                      PEAKS serves as our foundation for every project we
                      complete. This ensures you receive the highest level of
                      care, commitment, and expertise.
                    </p>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <QuandaryValues />
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
          <LazyLoadComponent>
            <section
              style={{
                padding: "1rem 0 3rem 0",
                background: "#fff",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center text-left-mobile">
                    {/* <StaticImage
                      placeholder="blurred"
                      imgClassName="img-fluid"
                      src="../images/new-images/approach/sprints.jpg"
                      alt="peakSUITE TRAILHEAD"
                      layout="constrained"
                      loading="lazy"
                    /> */}

                    <h2 className="about-titlehead">
                      Streamline Operations In Your Organization
                    </h2>

                    <p>
                      Data errors, slowdowns, and customer frustration aren’t
                      “growing pains”, they’re signs of a deeper problem. It’s
                      time to find and fix that problem.
                    </p>
                  </div>

                  <div className="col-lg-8 mx-auto text-center mt-5">
                    <Link
                      aria-label="link"
                      className="btn btn-primary btn-arrow"
                      to="/contact/"
                    >
                      Improve Your Systems
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>

          <LazyLoadComponent>
            <section
              className="quick-base-glance"
              style={{
                padding: "3rem 0",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="text-center mb-5">
                      Is Quandary Right for Your Business?
                    </h2>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <div className="text-center mx-auto">
                      <FcOrgUnit />
                    </div>
                    <h5 className="mt-4 mb-2 font-weight-bold">
                      Your Data Is
                      <br />
                      Fragmented
                    </h5>
                    <p>
                      Create custom, automated workflows without learning code.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <div className="text-center mx-auto">
                      <FcBullish />
                    </div>

                    <h5 className="mt-4 mb-2 font-weight-bold">
                      Your Profit Hasn’t Kept Pace with Growth
                    </h5>
                    <p>
                      Your operational costs continue to rise despite acquiring
                      new customers, training your team, and implementing
                      numerous cost-cutting techniques.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <div className="text-center mx-auto">
                      <FcSettings />
                    </div>
                    <h5 className="mt-4 mb-2 font-weight-bold">
                      You Need Custom
                      <br />
                      Applications
                    </h5>
                    <p>
                      You understand the value of investing in digital solutions
                      to scale your business. And you want them built to spec
                      within budget quickly.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
          <Testimonials2 />
          <LazyLoadComponent>
            <section style={{ padding: "3rem 0", backgroundColor: "#e7e7e7" }}>
              <div className="container">
                <div className="text-center">
                  <h2 className="mt-0 font-weight-medium">Contact Us</h2>
                  <p>
                    Use the form below to drop us an e-mail. We'll be in touch
                    shortly.
                  </p>
                </div>
                <div className="row">
                  {/* <div className="col-lg-6 col-md-12 mb-3 lg:mb-0 md:mb-0 mt-3 order-lg-first order-md-first order-last">
                    <TestimonialSwiper />
                  </div> */}
                  <div className="col-lg-6 mx-auto order-lg-last order-md-last order-first">
                    <div className="m-auto form-wrap-contact">
                      <div className="intro mb-2">
                        <div className="row">
                          <small className="text-red ml-3">
                            Note: Form fields with * are required and must be
                            filled up in order to submit.
                          </small>
                        </div>
                      </div>
                      <WebriQForm
                        id="contact-form"
                        name="Contact Form"
                        className="contactForm"
                        data-form-id="4d46fe65-4d66-46b4-946c-e5826349ed3c"
                        data-thankyou-url="/thank-you"
                      >
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            {/* <p class="required">Full Name</p> */}

                            <input
                              type="text"
                              name="Full Name"
                              size={40}
                              className="form-control required"
                              placeholder="Full Name *"
                              aria-label="Full Name"
                              required
                              id="requiredField"
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            {/* <p class="required">Phone</p> */}
                            <input
                              type="text"
                              name="Phone"
                              size={40}
                              // minLength={14}
                              className="form-control required"
                              id="phone"
                              placeholder="Phone Number *"
                              aria-label="Phone"
                              required
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            {/* <p class="required">Email</p> */}
                            <input
                              type="email"
                              name="Email"
                              size={40}
                              className="form-control"
                              aria-label="Email"
                              placeholder="Email *"
                              required
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            {/* <p class="required">Message</p> */}
                            <textarea
                              name="Message"
                              rows={10}
                              cols={0}
                              className="form-control"
                              aria-label="Message"
                              placeholder="Message *"
                              required
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            {/* <p class="required">How did you find out about us?</p> */}
                            <div>
                              <textarea
                                type="text"
                                name="How did you find out about us?"
                                size={40}
                                rows={5}
                                className="form-control"
                                aria-label="How did you find out about us?"
                                placeholder="How did you find out about us? *"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="form-group col-12 d-flex  captcha-wrap">
                            <div className="webriq-recaptcha" />
                          </div>
                          <div className="form-group col-12">
                            <div className="input-filled">
                              <button
                                type="submit"
                                className="btn btn-primary btn-arrow"
                              >
                                SUBMIT
                              </button>
                            </div>
                          </div>
                        </div>
                      </WebriQForm>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>

          <section id="case-studies-container">
            <div id="case-studies" className="bg-indigo">
              <div className="container">
                <div className="intro text-center">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                        Case Studies
                      </p>
                      <h2 className=" my-2 text-white">
                        We Save Clients 1000s of Hours. Every Year.
                      </h2>
                      {/* <p className="text-white">
                        We helped a national healthcare provider save over 9,000
                        hours in weekly labor costs by developing a single
                        application...
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
              {/* </Suspense> */}
            </div>
          </section>
          {/* <section
<section id="quotes" style={{ padding: "5rem 0" }}>
            <div className="container">
              <div className="col-lg-12 text-center">
                <h2>Looking for More Solutions? Explore Our Other Services:</h2>
                <Link
                  className="btn btn-primary btn-arrow mr-lg-4 mr-md-4 mt-4"
                  to="/quandary-connect/"
                >
                  Quandary Connect
                </Link>
                <Link
                  className="btn btn-primary btn-arrow mt-4"
                  to="/quandary-build/"
                >
                  Quandary Build
                </Link>
              </div>
            </div>
          </section>*/}
          {/* <section
            id="download-guide"
            style={{ background: "whitesmoke", padding: "5rem 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2>
                    Have more questions? Download our free guide to Technology
                    Consulting.
                  </h2>
                  <h3 className="mt-5">
                    Learn Why Growing Businesses Struggle to Scale Effectively
                  </h3>
                  <p className="mt-4">
                    Learn Why Growing Businesses Struggle to Scale Effectively
                  </p>
                  <p>
                    Remember why you started a business? Freedom. Flexibility.
                    The revenue to do what you wanted most in life. Quandary
                    Consulting Group makes that dream a reality. This quick
                    guide will show you how we using rapid application
                    development to build better systems for your business so you
                    can effortlessly scale without drowning in your success.
                  </p>
                </div>
                <div className="col-lg-6 align-self-center mt-lg-0 mt-mb-0 mt-3">
                  <img
                    src={FreeGuideItConsulting}
                    alt="Free guide to it consulting"
                    className="img-fluid w-100"
                  />
                  <Link
                    className="text-light btn btn-arrow btn-primary mt-3"
                    to="/consultation"
                  >
                    DOWNLOAD GUIDE NOW
                  </Link>
                </div>
              </div>
            </div>
          </section> */}
          {/* <section className="bg-white component pt-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6 order-2 order-md-1">
                  <div className="intro mb-4">
                    <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-3">
                      OUR STRATEGY
                    </h5>
                    <h2 className="mt-0">Transparency</h2>
                  </div>

                  <p>
                    Traditional consulting agencies work in a vacuum. It reduces
                    their efficiency and limits their overall potential.
                  </p>
                  <p>
                    Quandary believes in communication. Our team members share
                    ideas, insights, and feedback with each other on every
                    project we take on. That’s because we believe the best teams
                    have something to offer in any situation. These open lines
                    of communication cut down on the traditional problems that
                    plague consulting firms with noticeable results.
                  </p>
                  <p>
                    You get access to a consultancy that’s truly a team. A most
                    powerful asset.
                  </p>
                  <div className="d-flex justify-content-start mt-5">
                    <Link
                      className="btn btn-primary btn-arrow"
                      to="/consultation"
                    >
                      SCHEDULE YOUR FREE CONSULTATION
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-2 text-center pb-5 pb-md-0">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    initiallyVisible={false}
                    animateOnce={true}
                  >
                    <LazyLoadImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src={image1}
                      alt="Imagine freedom"
                    />
                  </ScrollAnimation>
                </div>
              </div>

              <div className="row mt-5 pt-4">
                <div className="col-md-6">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    initiallyVisible={false}
                    animateOnce={true}
                  >
                    <LazyLoadImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src={image2}
                      alt="Imagine freedom"
                    />
                  </ScrollAnimation>
                </div>
                <div className="col-md-6 pb-5 pb-md-0">
                  <div className="intro mb-4">
                    <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-3">
                      REACH NEW HEIGHTS
                    </h5>
                    <h2 className="mt-0">Discover P.E.A.K.S.</h2>
                  </div>

                  <p>
                    Team up with technology partners and discover how workflow
                    optimization can finally free your business.
                  </p>
                  <p>
                    Without the proper workflows in place, you could be wasting
                    precious time and resources on menial tasks. Your business
                    will struggle to reach its full potential while bogged down
                    with inefficient processes. Partner with a team ready to
                    listen before crafting the precise solutions your business
                    needs.
                  </p>
                  <div className="d-flex justify-content-start mt-5">
                    <Link
                      className="btn btn-primary btn-arrow"
                      to="/consultation"
                    >
                      SCHEDULE YOUR FREE CONSULTATION
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <section id="casestudy-list" className="component bg-gradient-gray">
            <div className="container">
              <div className="intro mb-5 text-center">
                <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-3">
                  CASE STUDIES
                </h5>
                <h2 className="mt-0">
                  Discover successful business optimization with Quandary on
                  your team.
                </h2>
              </div>
              <div id="case-study-wrapper">
                <CaseStudiesSwiper
                  slidesperview={3}
                  space={30}
                  responsive={{
                    575: { slidesPerView: 1 },
                    991: { slidesPerView: 3 },
                  }}
                >
                  {caseStudy.map(({ node }) => {
                    return (
                      <div className="swiper-slide">
                        <div className="case-study-inner">
                          <div className="case-study-featured">
                            <Link
                              className="case-study-overlay"
                              to={`/case-study/${node.slug.current}/`}
                            >
                              <LazyLoadImage
                                placeholder="blurred"
                                className
                                src={node.mainImage.asset.fluid.src}
                                alt={node.title}
                              />
                            </Link>
                            <Link
                              className="case-study-logo"
                              to="./case-study/low-code-efficiency-and-process-improvement/"
                            >
                              <LazyLoadImage
                                placeholder="blurred"
                                src="/assets/footer-logo.png"
                                alt="Low-Code Efficiency And Process Improvement"
                              />
                            </Link>
                          </div>
                          <div className="case-study-holder">
                            <div className="case-study-category">
                              {node.categories.map((a, idx) => {
                                const slugct = slugify(
                                  a.title.toString().toLowerCase()
                                )
                                return (
                                  <Link
                                    // to={
                                    //   a.slug
                                    //     ? a.slug.current
                                    //     : slugify(
                                    //         a.title.toString().toLowerCase()
                                    //       )
                                    // }
                                    to={`/case-study-category/${slugct}/`}
                                  >
                                    {(idx ? ", " : "") + a.title}
                                  </Link>
                                )
                              })}
                            </div>
                            <h3 className="case-study-title mt-0">
                              <Link aria-label='link' to={`/case-study/${node.slug.current}/`}>
                                {node.title}
                              </Link>
                            </h3>
                            <div className="case-study-more">
                              <Link aria-label='link' to={`/case-study/${node.slug.current}/`}>
                                VIEW FULL CASE STUDY{" "}
                                <i className="item-arrow" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </CaseStudiesSwiper>
              </div>

              <div className="mt-4 text-center">
                <p>
                  Need a custom solution to your business problem? Let's chat.
                  <br /> Call us for a free consultation at{" "}
                  <span>(720) 739-1406</span> or email us at{" "}
                  <a aria-label='link' href="mailto:info@quandarycg.com">info@quandarycg.com</a>
                </p>
              </div>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export default OurApproachPage

export const ApproachQuery = graphql`
  query ApproachQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
